var plazaresidence = (function(plazaresidence){
  
  'use strict';

  var unitType    = plazaresidence.unitType = plazaresidence.unitType || {},
      triggerUnit = '[data-toggle-unit]';

  unitType.init = function() {
  
    // toggle unit
   /* $(document).on('click', triggerUnit, unitType.toggle);*/
  }

  unitType.toggle = function(e) {
    var $this     = $(this),
        $parent   = $this.closest('#body'),
        target    = $this.attr('href'),
        isOpen    = $parent.hasClass('unit-type-open'),
        cardUnit  = $parent.find('#main')
                           .find('[href="'+ target +'"]')
                           .closest('.card-unit');

    target = target ? target : $this.attr('data-target');

    if(e) e.preventDefault();

    if(isOpen) {

      $parent.removeClass('unit-type-open');
      $parent.find('#main').removeClass('not-visible');
      $parent.find(target).addClass('not-visible');
      
      plazaresidence.util.scrollToElement(cardUnit, {
        offset: -20
      });

    } 
    else {
      
      $(window).scrollTop(0);

      $parent.addClass('unit-type-open');
      $parent.find('#main').addClass('not-visible');
      $parent.find(target).removeClass('not-visible');
    }

  }

  return plazaresidence;

}(plazaresidence || {}));