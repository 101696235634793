var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        widthviewport = w.innerWidth || e.clientWidth || g.clientWidth,
        heightviewport = w.innerHeight || e.clientHeight || g.clientHeight;

$(document).ready(function(){

    $('.m--navbar__icon').on('click', function(){
        $('html').toggleClass('show-dropdown-navbar');
    });

});

// adds mobile browser class to html tag. Special thanks to @ctcherry on github!
(function(){
    var ua = navigator.userAgent.toLowerCase().replace(/\s+/,'');

    var matchers = {
        ios: /(iphone|ipod|ipad)/,
        ipad: /ipad/,
        iphone: /(iphone|ipod)/,
        android: 'android',
        android2: 'android2',
        android4: 'android4',
        windowsphone: 'windows phone'
    }

    var h = $('html');

    for (i in matchers) {
        var m = matchers[i];
        if ((typeof(m) == "string" && ua.indexOf(m) > -1) || (typeof(m) == "object" && ua.match(m))) {
            h.addClass(i)
        }
    }

})();

(function(){
	var time = 4;
	var $bar,
	$slick,
	tick,
	percentTime;

	$slick = $('.home-carousel');
	$slick.each(function(){
		var dotsContainer = $(this).parents('.main--banner').find('.banner__control');
		$(this).slick({
			dots: true,
			slidesToShow: 1,
			fade: true,
			speed: 600,
			touchMove: false,
			pauseOnDotsHover: false,
			pauseOnHover: false,
			cssEase: 'linear',
			appendDots: dotsContainer,
			dotsClass: 'custom-dots',
			customPaging: function (slider, i) {
				var slideNumber = (i + 1),
				totalSlides = slider.slideCount;
				return '<a class="dot" role="button" title="' + slideNumber + ' of ' + totalSlides + '"></a><div class="slider-progress"><div class="progress"></div></div>';
			}
		});
	});
 	

	$bar = $('.slider-progress .progress');

	function startProgressbar() {
		resetProgressbar();
		percentTime = 0;
		tick = setInterval(interval, 10);
	}

	function interval() {
		percentTime += 1 / (time+0.1);
		$bar.css({
			width: percentTime+"%"
		});
		if(percentTime >= 100){
			$slick.slick('slickNext');
			startProgressbar();
		}
	}


	function resetProgressbar() {
		$bar.css({
			width: 0+'%' 
		});
		clearTimeout(tick);
	}

	return startProgressbar();

})();


$('#experience-carousel').slick({
	slidesToShow: 3,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 520,
			settings: {
				slidesToShow: 1
			}	
		}
	]
});

$('.datepick').datepicker({
	language: 'en',
	dateFormat: 'dd MM yyyy',
	navTitles: {days: 'MM <i>yyyy</i>'},
	autoClose: true,
	toggleSelected: false
});



(function(){
	jQuery.each(jQuery('textarea[data-autoresize]'), function() {
		var offset = this.offsetHeight - this.clientHeight;
		var max_height = 68; //3row
		var resizeTextarea = function(el) {
			jQuery(el).css('height', 'auto').css('height', Math.min(max_height, el.scrollHeight + offset));
		};
		jQuery(this).on('keyup input', function() { resizeTextarea(this); }).removeAttr('data-autoresize');
	});
})();


(function(){
    var window_scroll = 0;
    var delta = 10;
    var header = document.querySelector('.header');

    function show_top_bar(value){
        TweenMax.to(value, 0.5, {y:0, ease: Sine.ease, 'force3D':true});
    }
    function hide_top_bar(value){
        TweenMax.to(value, 0.5, {y:-28, ease: Sine.ease, 'force3D':true});
    }
    function onScroll(){
        window.addEventListener('scroll', hasScrolled)
    }
    function hasScrolled() {
        var current_window_scroll = $(this).scrollTop();
        // Make sure they scroll more than delta
        if(Math.abs(window_scroll - current_window_scroll) <= delta)
            return;

        if (current_window_scroll > window_scroll){
            hide_top_bar(header);
        } else {
            show_top_bar(header);
        }
        window_scroll = current_window_scroll;
    }

    if(header){
    	$(document).ready(onScroll);
    }

})();