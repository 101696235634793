var plazaresidence = (function(plazaresidence){
  
  'use strict';

  var vendor = plazaresidence.vendor = plazaresidence.vendor || {};

  vendor.slick = function(selector, options) {
    
    options = options || {};

    $(selector).slick(options);
  }

  vendor.montaseDropdown = {
    filterFloorPlan: function() {
      // single var pattern
      var $this           = $(this),
          $parent         = $this.closest('.montase-dropdown'),
          $filter         = $parent.find('.montase-dropdown__text'),
          text            = $this.find('a').text(),
          $li             = $parent.find('ul li'),
          selectorImageBg = '.section-floor-plan__img',
          dataImageUrl    = $this.attr('data-image-floorplan');

      $li.removeClass('active');

      $this.addClass('active');
      $filter.text(text);

      _changeImageBg(selectorImageBg, dataImageUrl);

    }
  }

  vendor.chosen = function(selector, options) {
    var DEFAULTS = {
      disable_search_threshold: 10,
      placeholder_text_single: 'Choose option'
    };

    options = $.extend(DEFAULTS, options);

    // 
    $(selector).on('chosen:ready', function(e, params) {
      var $select   = $(e.currentTarget),
          $chosenCt = $select.next(), // element build by chosen
          validClass = _setValidClass(selector);
      
      $chosenCt.addClass(validClass);
    
    });

    $(selector).chosen(options);
  }

  vendor.collapse = {
    setCustomInFacilities: function() {
      var $fcc = $('.facilities-collapse__content');

      $fcc.on('show.bs.collapse', function(e){
        var $this = $(e.currentTarget),
            $parent = $this.closest('.facilities-collapse');
        $parent.addClass('open');
      });

      $fcc.on('hide.bs.collapse', function(e){
        var $this = $(e.currentTarget),
            $parent = $this.closest('.facilities-collapse');
        $parent.removeClass('open');
      });
    }
  }

  var _changeImageBg = function(selectorImageBg, dataImageUrl) {
    var $el = $(selectorImageBg);
    $el.css({
      backgroundImage: "url("+dataImageUrl+")"
    });
  }

  var _setValidClass = function(str) {
    return str.replace(/\./g,' ');
  }

  return plazaresidence;

}(plazaresidence || {}));