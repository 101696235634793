var plazaresidence = (function($, plazaresidence){
  'use strict';
  var util = plazaresidence.util = plazaresidence.util || {};

  var $container = $('.js-form-response')

  var _addError = function(targetSelector, settings) {
    var openEl = '<p>',
        closeEl = '</p>'
    var DEFAULTS = {
      message: 'this field required.'
    }

    settings = $.extend(DEFAULTS, settings) 

    
    $(targetSelector)
        .addClass('form-error')
        .append(openEl + settings.message + closeEl);

  }

  var _deleteError = function(targetSelector) {
    $(targetSelector)
        .removeClass('form-error')
    $(targetSelector + ' p').remove()
  }

  var _setMessage = function(settings) {
   
    var isExist = $container.find('.alert').length === 0
    var $el;

    var DEFAULTS = {
      priority: 'success',
      title: 'MESSAGE SENT',
      message: 'Thank you. We will contact you back soon!'
    }

    settings = $.extend(DEFAULTS, settings) 

    var $alert      = $('<div class="alert alert-' + settings.priority + ' mb_46"></div>')
    var $alertImg   = $('<img src="/images/global/' + settings.priority + '-icon.png" class="alert__icon" />')
    var $alertBody  = $('<div class="alert__body text-left"></div>')
    var $alertTitle = $('<h4 class="alert__title playfair bold ls_0 lh_24 size_18 mb_3">' + settings.title + '</h4>')     
    var $alertMsg   = $('<p class="alert__message forum size_18 ls_0 lh_19">' + settings.message + '</p>')
    
    if(isExist) {
      $el = $alertBody
              // .append($alertTitle)
              .append($alertMsg)
      $el = $alert.append($alertImg)
                  .append($el)

      $container.append($el)
      return $el
    }
        
  }

  var _clearMessage = function() {
    $container.empty()
  }

  util.scrollToElement = function scrollToElement (el, options) {

    var DEFAULTS = {
      offset: 0
    }

    options = $.extend(DEFAULTS, options)
    
    var offsetFromWindow = $(el).offset().top,
        offsetFromWindow = parseInt(offsetFromWindow) + parseInt(options.offset);

    $(window).scrollTop(offsetFromWindow)
  }

  // just work on select single value
  util.setSelectDefault = function() {
    var $select       = $('select.form-control'),
        defaultVal    = $select.find('option').eq(0).text(),
        selectedValue,
        $this,
        isEqual; 

    function _init() {
      $select.addClass('select-default');
    }

    function _toggle(e) {
      $this         = $(e.currentTarget);
      selectedValue = $this.val();
      isEqual       = defaultVal === selectedValue ? true : false;

      if(isEqual) {
        $this.addClass('select-default');
      } 
      else {
        $this.removeClass('select-default');
      }

    }

    // api definition
    $select.on('change', _toggle);
    $(document).ready(_init);
  }

  util.form = {
    addError: function(targetSelector, settings){
     return  _addError(targetSelector, settings)
    },
    deleteError: function(targetSelector) {
      return _deleteError(targetSelector)
    },
    setMessage: function(settings) {
      return _setMessage(settings)
    },
    clearMessage: function() {
      return _clearMessage()
    }
  }

  return plazaresidence;
  
}(jQuery, plazaresidence || {}));